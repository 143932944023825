<template>
  <v-dialog persistent :value="dialogShow" :max-width="maxWidth">
    <v-card class="dialog-card">
      <v-row justify="space-between" no-gutters :class="$vuetify.breakpoint.mdAndDown ? 'dialog-header pt-1 px-9' : 'dialog-header pt-1 px-11'">
        <v-col>
          <v-img v-if="dialogImage" :src="dialogImage" alt="Dialog Image" />
        </v-col>
        <v-col cols="11">
          <label v-if="!isAuth && !isRegSuccess" class="dialog-title font-weight-bold pt-3 ma-0 text-capitalize d-block full-width">
            {{ title }}
          </label>
          <img v-if="isRegSuccess" src="/static/svg/logo.svg" alt width="133" height="22" style="float:left;" />
        </v-col>
        <v-col cols="1">
          <v-icon :class="['dialog-close-icon', this.header_title == 'register' ? 'register' : this.header_title == 'login' ? 'login' : '']" @click="closeAction" v-if="closeAction != null">
            mdi-close
          </v-icon>
        </v-col>
        <v-col cols="12" class="pt-2" v-if="subtitle">
          <label class="full-width dialog-subtitle">{{ subtitle }}</label>
        </v-col>
      </v-row>
      <v-row no-gutters :class="$vuetify.breakpoint.mdAndDown ? 'px-9 py-1' : 'px-11 pt-6'">
        <v-col cols="12">
          <v-divider v-if="showDivider" class="dialog-divider"></v-divider>
        </v-col>
      </v-row>
      <!-- v-if is to allow component to reinitialize it Life cycle -->
      <v-row no-gutters class="d-block dialog-row" :class="customClass" v-if="dialogShow">
        <slot></slot>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'appDialog',
  props: {
    header_title: {
      type: String,
      required: false
    },
    isAuth: {
      type: Boolean,
      default: false,
      required: false
    },
    showDivider: {
      type: Boolean,
      default: true
    },
    closeAction: {
      required: false,
      default: null
    },
    dialogImage: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    maxWidth: {
      type: Number,
      required: false,
      default: 400
    },
    minHeight: {
      type: Number,
      required: false,
      default: 500
    },
    dialogShow: {
      type: Boolean,
      required: true,
      default: false
    },
    customClass: {
      type: String,
      required: false,
      default: 'pa-6 pt-0'
    },
    openRegisterDialog: {
      type: Function,
      default: () => ({})
    },
    openLoginDialog: {
      type: Function,
      default: () => ({})
    },
    isRegSuccess: {
      type: Boolean,
      default: false,
      required: false
    },
    subtitle: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>

<style>
/* .logo-color {
        .path.b {
            fill: #fddd04;
        }
    } */
.v-dialog {
  overflow: hidden;
}
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 95%;
}
</style>

<style lang="scss" scoped>
.dialog-logo {
  width: 133px;
  height: 22px;
  padding-top: 4px;
}
.dialog-card {
  .dialog-title {
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
  }
  .dialog-subtitle {
    font-size: 16px;
  }
}
.dialog-header {
  position: relative;
}
.dialog-close-icon {
  background-color: #ffce01;
  font-size: xx-large;
  position: absolute;
  top: 0px !important;
  right: 0px;
  z-index: 9999;
}
.dialog-row {
  overflow-y: scroll;
}
.dialog-divider {
  border-width: 0.8px !important;
  border-color: #d3d3d3 !important;
  height: 100%;
}
@media (max-height: 600px) {
  .dialog-row {
    max-height: 500px;
  }
  .dialog-card {
    .dialog-title {
      font-size: 18px !important;
    }
  }
}
@media (max-height: 400px) {
  .dialog-row {
    height: 200px;
  }
}

@media (max-width: 425px) {
  .dialog-card {
    .dialog-title {
      font-size: 16px !important;
    }
    .dialog-subtitle {
      font-size: 12px !important;
    }
  }
}
</style>
